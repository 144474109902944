import React, { useEffect, useMemo } from 'react';

import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';

import { msgs } from '@config/conf';

import ProductsForm from '../ProductsForm/ProductsForm';

const EditProduct = () => {
  const editProductForm = useForm({
    mode: 'onChange',
  });

  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const page = query.get('from');
  const products = useSelector(state => state.products);
  const { enqueueSnackbar } = useSnackbar();

  const pageSize = query.get('pageSize') || '';
  const sort = {
    sortProductName: query.get('sortProductName') || '',
    sortCarrierName: query.get('sortCarrierName') || '',
  };

  const sortProductNamePath = sort.sortProductName
    ? `&sortProductName=${sort.sortProductName}`
    : '';
  const sortCarrierNamePath = sort.sortCarrierName
    ? `&sortCarrierName=${sort.sortCarrierName}`
    : '';

  const response = useMemo(
    () => products?.requesting && (
    <p className="text-center text-primary mb-3">
      <span className="spinner-border spinner-border-sm" />
    </p>
      ),
    [products?.requesting],
  );

  useEffect(() => {
    if (products?.err) {
      enqueueSnackbar(products.err || msgs.error, {
        variant: 'error',
        autoHideDuration: 5000,
        preventDuplicate: true,
      });
    } else if (products?.success) {
      enqueueSnackbar(msgs.success, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      history.push(
        `/products?page=${page}${sortProductNamePath}${sortCarrierNamePath}${
          pageSize ? '&pageSize=' + pageSize : ''
        }`,
      );
    }
  }, [products?.err, products?.success]);

  return (
    <Container>
      <h3 className="mb-3 text-center">Product Detail</h3>
      {response}
      <div className="product_wrapper">
        <ProductsForm
          productForm={editProductForm}
          page={page}
          pageSize={pageSize}
          sort={sort}
        />
      </div>
    </Container>
  );
};

export default EditProduct;
