import React, { useState, useEffect } from 'react';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'reactstrap';

import { conf } from '@config/conf';
import { getAnnuityDocuments, logoffAnnuitantToken } from '@redux/actions/annuityActions';

import Navbar from './Navbar';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const AnnuityDocumentVault = () => {
  const [isLoading, setLoading] = useState(true);
  const annuityAuthToken = sessionStorage.getItem('annuityAuthToken');
  const reAccountNumber = sessionStorage.getItem('reAccountNumber');
  // const navigateBackUrl = `/View/Account/Summary/${sessionStorage.getItem("reAccountNumber")}?token=${sessionStorage.getItem("annuityAuthToken")}`;
  const windowLocationPath = sessionStorage.getItem('window.location.pathname');
  let navigateBackUrl = `${windowLocationPath}?token=${sessionStorage.getItem('annuityAuthToken')}`;

  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const annuityRes = useSelector(state => state.annuity);

  let isAnnuitantSystemUser = true;
  if (windowLocationPath.includes('GenericUser')) {
    isAnnuitantSystemUser = false;
    navigateBackUrl = `${windowLocationPath}`;
  }

  const getData = () => {
    dispatch(getAnnuityDocuments(annuityAuthToken, reAccountNumber, isAnnuitantSystemUser));
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const invalidateAnnuitantToken = () => {
    if (windowLocationPath.includes('GenericUser')) {
      const credentials = {
        token: annuityAuthToken,
        loginName: 'GenericAnnuitantUser',
        userType: 'Annuitant',
        reAccountNumber,
      };
      dispatch(logoffAnnuitantToken(credentials));
    } else {
      sessionStorage.setItem('annuityAuthToken', null);
      sessionStorage.removeItem('activeTab');
      const credentials = {
        token: annuityAuthToken,
        loginName: 'annuitysystemuser',
        userType: 'Annuitant',
      };
      dispatch(logoffAnnuitantToken(credentials));
    }
  };

  const handleDocumentView = async (docId) => {
    await axios
      .get(conf.serverURL + 'reaccounts/annuitydocumentvault/document/' + docId, {
        headers: {
          'Content-Type': 'application/json',
          accessToken: sessionStorage.getItem('annuityAuthToken'),
        },
      })
      .then((resp) => {
        window.open(resp.data?.data?.documentURL, '_blank');
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          // // [TODO: Arun]
          // eslint-disable-next-line no-undef
          logout();
          // // [TODO: Arun]
          // eslint-disable-next-line no-undef
          enqueueSnackbar(msgs.authError, {
            variant: 'error',
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar(
            // eslint-disable-next-line no-undef
            err.response?.data.message || err.message || msgs.error,
            {
              variant: 'error',
              autoHideDuration: 5000,
            },
          );
        }
      });
  };

  if (isLoading) {
    return (
      <div className="pt-5">
        <h1 className="text-center text-primary">
          <span className="spinner-grow" />
        </h1>
      </div>
    );
  }

  if (annuityRes?.requestingDocuments) {
    return (
      <div className="pt-5">
        <h1 className="text-center text-primary">
          <span className="spinner-grow" />
        </h1>
      </div>
    );
  }

  if (annuityRes?.err) {
    return (
      <div className="pt-5">
        <h1 className="text-center text-danger">
          {annuityRes.err}
        </h1>
      </div>
    );
  }

  return (
    <Container>
      <Navbar style={{ width: '100%', padding: '10px', margin: '10px' }} userFirstName={sessionStorage.getItem('userFirstName')} invalidateAnnuitantToken={invalidateAnnuitantToken} />

      <Container>
        <div style={{ marginTop: '25px', marginBottom: '25px' }}>
          <Typography variant="button" style={{ marginTop: '10px' }}>
            <Button href={navigateBackUrl} variant="outlined" color="primary">Back to Policy Page</Button>
          </Typography>
        </div>

        <div style={{ marginTop: '25px', marginBottom: '25px' }}>
          <Typography variant="button">
            Statements & Policy Documents
          </Typography>
        </div>

        <TableContainer component={Paper}>
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell width="10%" align="center" style={{ border: '3px solid white', color: 'white', backgroundColor: 'rgb(113, 153, 187)' }}><Typography variant="button">View</Typography> </TableCell>
                <TableCell
                  width="35%"
                  align="left"
                  style={{
                    border: '3px solid white', color: 'white', backgroundColor: 'rgb(113, 153, 187)', paddingLeft: '10px',
                  }}
                >  <Typography variant="button">Document Title</Typography>
                </TableCell>
                <TableCell width="15%" align="left" style={{ border: '3px solid white', color: 'white', backgroundColor: 'rgb(113, 153, 187)' }}><Typography variant="button">Policy Number</Typography></TableCell>
                <TableCell width="15%" align="left" style={{ border: '3px solid white', color: 'white', backgroundColor: 'rgb(113, 153, 187)' }}><Typography variant="button">Type</Typography></TableCell>
                <TableCell width="15%" align="left" style={{ border: '3px solid white', color: 'white', backgroundColor: 'rgb(113, 153, 187)' }}><Typography variant="button">Category</Typography></TableCell>
                <TableCell width="10%" align="right" style={{ border: '3px solid white', color: 'white', backgroundColor: 'rgb(113, 153, 187)' }}><Typography variant="button">Post Date</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {annuityRes?.data?.data.map(row => (
                <TableRow key={row.documentID}>
                  <TableCell width="10%" align="center" component="th" scope="row">
                    <a style={{ cursor: 'pointer' }} onClick={() => handleDocumentView(row.documentID)}>
                      <i className="fa fa-file-text fa-lg" style={{ color: 'rgb(113, 153, 187)' }} />
                    </a>
                  </TableCell>
                  <TableCell width="35%" align="left" component="th" scope="row">{row.documentTitle}</TableCell>
                  <TableCell width="15%" align="left">{row.policyNumber }</TableCell>
                  <TableCell width="15%" align="left">{row.taxCode}</TableCell>
                  <TableCell width="10%" align="left">{row.documentType}</TableCell>
                  <TableCell width="10%" align="right">{new Date(row.documentPostedDate).toLocaleDateString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>

    </Container>
  );
};

export default AnnuityDocumentVault;
