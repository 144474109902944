import React, {
 useCallback, useEffect, useMemo, useState,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, Badge } from 'reactstrap';

import {
 Table, Spinner, Modal, ExportToCsv,
} from '@atoms';
import { pagination } from '@config/conf';
import { formatCurrency, getPagination } from '@containers/components/helpers';
import { useAuth } from '@contexts/AuthContext';
import useApiFetch from '@hooks/useApiFetch';
import useCsvExportButton from '@hooks/useCsvExportButton';
import useQueryParams from '@hooks/useQueryParams';
import { formatDate } from '@lib/date';
import { formatFixedDigits } from '@lib/number';
import { getPaws } from '@redux/actions/pawsActions';

import PurchasesWithdrawalsSearch from './PurchasesWithdrawalsSearch';

const pandwWorkflowColumn = [
  {
    id: 'reAccountNumber',
    header: 'RE Account #',
    accessorKey: 'reAccountNumber',
  },
  {
    id: 'result',
    header: 'Result',
    accessorKey: 'result',
  },
  {
    id: 'activityDate',
    header: 'Posted',
    accessorFn: row => formatDate(row?.activityDate, 'MM/DD/YY'),
  },
  {
    id: 'activityAmount',
    header: 'Amount',
    cell: (tableRow) => {
      const { activityAmount } = tableRow.row.original || {};
      return (
        <>
          {activityAmount < 0 ? (
            <span className="text-danger">
              {formatCurrency(Math.abs(activityAmount), 2)}
            </span>
          ) : (
            formatCurrency(activityAmount, 2)
          )}
        </>
      );
    },
  },
  {
    id: 'childWorkflowNumber',
    header: 'WF#',
    accessorFn: row => (row?.childWorkflowNumber
        ? formatFixedDigits(row?.childWorkflowNumber, 12)
        : '-'),
  },
  {
    id: 'workflowStatus',
    header: 'Status',
    accessorFn: row => `${row?.childWorkflowStatus || ''} / ${
        row?.childWorkflowSubStatus || ''
      }`,
  },
];

const PandWWorkflowLogTable = (subTableprops) => {
  const { workflowData } = subTableprops.row.original || {};
  return (
    <Table columns={pandwWorkflowColumn} data={workflowData} hasNestedTable />
  );
};

const PurchasesWithdrawalsTable = ({ refresh, setRefresh }) => {
  const { token } = useAuth();
  const dispatch = useDispatch();
  const { post, isPending } = useApiFetch();
  const history = useHistory();
  const { getQueryParam, buildQueryParams } = useQueryParams();
  const pandwData = useSelector(state => state.paws);

  const [exportingId, setExportingId] = useState('');
  const [rejectModal, setRejectModal] = useState(false);
  const [submitModal, setSubmitModal] = useState(false);
  const [confirmSubmitModal, setConfirmSubmitModal] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [wfID, setWfID] = useState('');
  const [pwSearch, setPwSearch] = useState(getQueryParam('pw') || '');
  const [pwStatus, setPwStatus] = useState(getQueryParam('status') || '');
  const [pwCustodian, setPwCustodian] = useState(
    getQueryParam('custodian') || '',
  );
  const [submitSearch, setSubmitSearch] = useState(false);
  const [pageSize, setPageSize] = useState(
    getQueryParam('pageSize') || pagination.length,
  );

  const sortWfNumber = getQueryParam('sortWfNumber') || '';
  const sortWfStartDate = getQueryParam('sortWfStartDate') || '';
  const sortWfStatus = getQueryParam('sortWfStatus') || '';
  const page = getQueryParam('page');
  const { offset, pageNumber } = getPagination(pageSize, page);

  const {
    csvClickRef,
    csvData: { data: exportData = [] },
    getExport,
    loadingResponse,
  } = useCsvExportButton();

  const handleCsvExport = (workflowID) => {
    setExportingId(workflowID);
    getExport(`operations/pandw/${workflowID}`);
  };

  const {
    err,
    requesting,
    paws: { recordCount, data },
  } = pandwData || {};

  const refreshString = useCallback(() => {
    if (sortWfNumber) {
      return `Workflows.WorkflowNumber ${sortWfNumber.toUpperCase()}`;
    }
    if (sortWfStartDate) {
      return `Workflows.WorkflowStartDate ${sortWfStartDate.toUpperCase()}, Workflows.WorkflowNumber DESC`;
    }
    if (sortWfStatus) {
      return `Workflows.Status ${sortWfStatus.toUpperCase()}, Workflows.WorkflowNumber DESC`;
    }
    return '';
  }, [sortWfNumber, sortWfStartDate, sortWfStatus]);

  const refStr = refreshString();

  const queryParams = buildQueryParams({
    pw: pwSearch,
    status: pwStatus,
    custodian: pwCustodian,
    sortWfNumber,
    sortWfStartDate,
    sortWfStatus,
    pageSize,
  });

  useEffect(() => {
    if (submitSearch) {
      history.push(`/purchases-and-withdrawals?page=1${queryParams}`);
    } else if (token) {
      dispatch(
        getPaws(
          token,
          pageSize,
          offset,
          refStr,
          pwSearch,
          pwStatus,
          pwCustodian,
        ),
      );
    }
    setRefresh(false);
    setSubmitSearch(false);
  }, [pageSize, offset, refresh, refStr, submitSearch]);

  const handleAction = useCallback(
    (workflowID, actionType) => {
      if (isPending) {
        setAlertModal(true);
        return;
      }
      setWfID(workflowID);
      if (actionType === 'submit') {
        setSubmitModal(!submitModal);
      } else if (actionType === 'reject') {
        setRejectModal(!rejectModal);
      }
    },
    [isPending, submitModal, rejectModal],
  );

  const pandwColumn = useMemo(
    () => [
      {
        id: 'expanded-chevron',
        header: '',
        size: 0,
        cell: (tableRow) => {
          const { expandedRowIds, row } = tableRow || {};
          const className = `lnr ${
            expandedRowIds?.includes(row.id)
              ? 'lnr-chevron-down'
              : 'lnr-chevron-right'
          }`;
          return (
            <div className="expanded_chevron">
              <i className={className} />
            </div>
          );
        },
      },
      {
        id: 'wfNumber',
        enableSorting: true,
        header: 'WF#',
        accessorFn: row => formatFixedDigits(row?.workflowNumber, 12),
      },
      {
        id: 'wfStartDate',
        enableSorting: true,
        header: 'Start Date',
        accessorFn: row => formatDate(row?.workflowStartDate, 'MM/DD/YY'),
      },
      {
        id: 'wfStatus',
        header: 'Status',
        accessorFn: row => `${row?.status} / ${row?.subStatus}`,
      },
      {
        id: 'custodianName',
        header: 'Custodian',
        accessorKey: 'custodianName',
      },
      {
        id: 'totalAcceptAndReject',
        header: <span className="column-center">A / R</span>,
        cell: (tableRow) => {
          const { totAccepted, totRejected } = tableRow?.row?.original || {};
          return (
            <div className="column-center">
              {`${totAccepted || 0} / ${totRejected || 0}`}
            </div>
          );
        },
      },
      {
        id: 'totalPurchaseAndWithdrawals',
        header: <span className="column-end">P / W</span>,
        cell: (tableRow) => {
          const { totPurchases, totWithdrawals } = tableRow?.row?.original || {};
          return (
            <div className="column-end">
              {formatCurrency(Math.abs(totPurchases), 2)} /{' '}
              <span className="text-danger">
                ({formatCurrency(Math.abs(totWithdrawals), 2)})
              </span>
            </div>
          );
        },
      },
      {
        id: 'actions',
        header: '',
        cell: (tableRow) => {
          const {
 status, subStatus, totAccepted, workflowID, workflowNumber,
} = tableRow.row.original || {};
          const isExporting = exportingId === workflowID;

          return (
            <div className="flex justify-content-end pr-2 my-1">
              {status === 'In Progress' && subStatus === 'Accepted' ? (
                <>
                  <Badge
                    color="danger"
                    className="pointer mr-1 h-max-content"
                    onClick={() => handleAction(workflowID, 'reject')}
                  >
                    Reject
                  </Badge>
                  {totAccepted > 0 ? (
                    <Badge
                      color="success"
                      className="pointer mr-1 h-max-content"
                      onClick={() => handleAction(workflowID, 'submit')}
                    >
                      Submit
                    </Badge>
                  ) : null}
                </>
              ) : null}
              <ExportToCsv
                csvRef={csvClickRef}
                fileName={formatFixedDigits(workflowNumber, 12)}
                getCsvExport={() => handleCsvExport(workflowID)}
                loadingResponse={isExporting && loadingResponse}
                tableCellSpecifier={{ hasExactCell: isExporting }}
                component="badge"
                csvData={exportData}
              />
            </div>
          );
        },
      },
    ],
    [exportData, exportingId, handleAction, loadingResponse],
  );

  const pageProps = {
    total: recordCount,
    pageSize,
    setPageSize,
    pageNumber,
  };

  const handleRejectWorkflow = useCallback(async () => {
    await post(`operations/pandw/reject/${wfID}`, {});
    setRefresh(true);
  }, [wfID, refresh]);

  const handleSubmitWorkflow = useCallback(async () => {
    await post(`operations/pandw/submit/${wfID}`, {});
    setRefresh(true);
  }, [wfID, refresh]);

  const modals = useMemo(
    () => [
      {
        modalName: 'reject-modal',
        isModalOpen: rejectModal,
        setIsModalOpen: setRejectModal,
        content: 'Are you sure you wish to reject this workflow?',
        buttonProps: [
          {
            name: 'Cancel',
            color: 'danger',
            onClick: () => setRejectModal(!rejectModal),
          },
          {
            name: 'Confirm',
            color: 'primary',
            onClick: () => {
              handleRejectWorkflow();
              setRejectModal(false);
            },
          },
        ],
      },
      {
        modalName: 'submit-modal',
        isModalOpen: submitModal,
        setIsModalOpen: setSubmitModal,
        content: 'Are you sure you wish to submit this workflow?',
        buttonProps: [
          {
            name: 'Cancel',
            color: 'danger',
            onClick: () => setSubmitModal(!submitModal),
          },
          {
            name: 'Confirm',
            color: 'primary',
            onClick: () => {
              setConfirmSubmitModal(!confirmSubmitModal);
              setSubmitModal(false);
            },
          },
        ],
      },
      {
        modalName: 'confirm-submit-modal',
        isModalOpen: confirmSubmitModal,
        setIsModalOpen: setConfirmSubmitModal,
        content: 'Please confirm funds have been recieved and verified?',
        buttonProps: [
          {
            name: 'Cancel',
            color: 'danger',
            onClick: () => setConfirmSubmitModal(!confirmSubmitModal),
          },
          {
            name: 'Confirm',
            color: 'primary',
            onClick: () => {
              handleSubmitWorkflow();
              setConfirmSubmitModal(false);
            },
          },
        ],
      },
      {
        modalName: 'alert-modal',
        isModalOpen: alertModal,
        setIsModalOpen: setAlertModal,
        content:
          'Another Submit or Reject action is in process. Please wait before submitting another one.',
        buttonProps: [
          {
            name: 'Ok',
            color: 'danger',
            onClick: () => setAlertModal(!alertModal),
          },
        ],
      },
    ],
    [
      alertModal,
      confirmSubmitModal,
      handleRejectWorkflow,
      handleSubmitWorkflow,
      rejectModal,
      submitModal,
    ],
  );

  return (
    <>
      {modals?.map((modal) => {
        const {
 buttonProps, content, isModalOpen, setIsModalOpen, modalName,
} = modal || {};
        return (
          <div key={modalName}>
            <Modal
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              content={content}
              modalButtons={buttonProps}
            />
          </div>
        );
      })}
      <Spinner requesting={requesting}>
        <Card className="pb-2">
          <CardBody className="py-2 bg-green">
            <PurchasesWithdrawalsSearch
              pwCustodian={pwCustodian}
              setPwCustodian={setPwCustodian}
              pwSearch={pwSearch}
              setPwSearch={setPwSearch}
              pwStatus={pwStatus}
              setPwStatus={setPwStatus}
              setSubmitSearch={setSubmitSearch}
            />
          </CardBody>
        </Card>
        <Table
          columns={pandwColumn}
          data={data}
          renderNestedTable={PandWWorkflowLogTable}
          errStatus={err}
          pageProps={pageProps}
        />
      </Spinner>
    </>
  );
};

export default PurchasesWithdrawalsTable;
