import { useEffect, useMemo } from 'react';

import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';

import { Input, Select, FormFooterActions } from '@atoms';
import { msgs } from '@config/conf';
import { filterOptions } from '@containers/components/helpers';
import { useAuth } from '@contexts/AuthContext';
import { getEps } from '@redux/actions/epsActions';
import { emailPattern } from '@shared/helpers';

const carrierFormFields = [
  {
    label: 'Carrier Name',
    name: 'carrierName',
    maxLength: 200,
    isRequired: true,
  },
  {
    label: 'Carrier Code',
    name: 'extCarrierCode',
    maxLength: 200,
    isRequired: true,
  },
  {
    label: 'Contact Name',
    name: 'contactName',
    maxLength: 100,
  },
  {
    label: 'Contact Phone',
    name: 'contactPhone',
    maxLength: 50,
  },
  {
    label: 'Contact Email',
    name: 'contactEmail',
    maxLength: 100,
    type: 'email',
  },
  {
    label: 'Bank Name',
    name: 'bankName',
    maxLength: 200,
  },
  {
    label: 'Routing Number',
    name: 'routingNumber',
    maxLength: 50,
  },
  {
    label: 'Account Number',
    name: 'accountNumber',
    maxLength: 50,
  },
];

const CarrierForm = ({ carriers, redirectUrl, onSubmit }) => {
  const { control, reset, handleSubmit } = useForm();
  const { token } = useAuth();
  const dispatch = useDispatch();
  const endPointSet = useSelector(state => state.eps);
  const filteredEndPointSet = endPointSet?.eps?.data?.filter(item => Boolean(item.endpointSetID));

  useEffect(() => {
    if (token) {
      dispatch(getEps(token));
    }
  }, []);

  useEffect(() => {
    reset({ ...carriers });
  }, [reset, carriers]);

  const endPointSetOptions = useMemo(
    () => filterOptions(filteredEndPointSet, 'endpointSetID', 'endpointSetName'),
    [filteredEndPointSet],
  );

  const requiredProps = {
    control,
    required: true,
    rules: { required: msgs.fieldError },
  };

  return (
    <Card className="container_width">
      <CardBody>
        <div className="form form--horizontal form_spacing">
          {carrierFormFields?.map(
            ({
              label,
              maxLength,
              name,
              isRequired,
              type,
            }) => {
              const commonProps = isRequired
                ? { ...requiredProps }
                : { control };

              if (type === 'email') {
                return (
                  <Input
                    control={control}
                    key={name}
                    label={label}
                    name={name}
                    maxLength={maxLength}
                    rules={{
                      pattern: {
                        value: emailPattern,
                        message: 'Email is invalid.',
                      },
                    }}
                  />
                );
              }
              return (
                <Input
                  {...commonProps}
                  key={name}
                  label={label}
                  name={name}
                  maxLength={maxLength}
                />
              );
            },
          )}
          <Select
            name="endpointSetID"
            label="Endpoint Set"
            options={endPointSetOptions}
            isLoading={endPointSet?.requesting}
            errorMessage={endPointSet?.err}
            {...requiredProps}
          />
          <FormFooterActions
            linkProps={[{ path: redirectUrl }]}
            buttonProps={[{ onClick: handleSubmit(onSubmit) }]}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default CarrierForm;
