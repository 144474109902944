import React, {
 useCallback, useEffect, useMemo, useState,
} from 'react';

import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, CustomInput } from 'reactstrap';

import { Spinner, Table, FormFooterActions } from '@atoms';
import { msgs } from '@config/conf';
import { useAuth } from '@contexts/AuthContext';
import useQueryParams from '@hooks/useQueryParams';
import { getPppios, updatePppios } from '@redux/actions/pppiosAction';

const EditPIOs = (props) => {
  const {
    match: {
      params: { planProductId, productId },
    },
  } = props;

  const { token } = useAuth();
  const dispatch = useDispatch();
  const piosData = useSelector(state => state.pppios);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { getQueryParam, buildQueryParams } = useQueryParams();

  const [submitting, setSubmitting] = useState(false);
  const [pioIds, setPioIDs] = useState([]);

  const search = getQueryParam('search');
  const sortPlanName = getQueryParam('sortPlanName');
  const sortStatus = getQueryParam('sortStatus');
  const planId = getQueryParam('planId');
  const page = getQueryParam('from');

  const {
    err,
    requesting,
    pppios: { data = [] },
    success,
  } = piosData || {};

  useEffect(() => {
    if (token) {
      dispatch(getPppios(token, planProductId, productId));
    }
  }, [planProductId, productId]);

  const activePlanProductPios = useMemo(
    () => (data.length ? data.filter(data => data?.status === 'Active') : []),
    [data],
  );

  const navigatePathQuery = useMemo(() => {
    const query = buildQueryParams({
      search,
      sortPlanName,
      sortStatus,
    });
    return `/plans?page=${page}&id=${planId}${query}`;
  }, [planId]);

  const getSelectedPioIds = selectedPIOs => setPioIDs((prevIds) => {
      const currentIds = prevIds.map(pio => pio?.pioid);
      const newSelectedIds = selectedPIOs?.map(item => item?.pioid);
      const updatedIds = [
        ...selectedPIOs
          .filter(item => !currentIds.includes(item?.pioid))
          .map(item => ({ pioid: item?.pioid })),
        ...prevIds.filter(pio => newSelectedIds.includes(pio?.pioid)),
      ];
      return updatedIds;
    });

  useEffect(() => {
    const selectedPIOs = data?.filter(item => item?.pioSelected);
    selectedPIOs?.length ? getSelectedPioIds(selectedPIOs) : [];
  }, [data]);

  const handleCheckboxChange = (pioid) => {
    setPioIDs((prevIds) => {
      const isSelected = prevIds.some(val => val.pioid === pioid);
      if (isSelected) {
        return prevIds.filter(id => id.pioid !== pioid);
      }
      return [...prevIds, { pioid }];
    });
  };

  const column = [
    {
      id: 'pioName',
      header: <div className="pl-4 ml-3">Investment Option</div>,
      size: '75%',
      cell: (tableRow) => {
        const { pioName, pioid } = tableRow?.row?.original || {};
        const isSelectedPioIds = pioIds?.some(val => val?.pioid === pioid);
        return (
          <div key={pioid} className="flex">
            <CustomInput
              type="checkbox"
              name={`pioid-${pioid}`}
              id={pioid}
              onChange={() => handleCheckboxChange(pioid)}
              checked={isSelectedPioIds}
            />
            <span className="ml-3">{pioName}</span>
          </div>
        );
      },
    },
    {
      id: 'status',
      header: 'Status',
      accessorKey: 'status',
    },
  ];

  useEffect(() => {
    if (success && submitting) {
      enqueueSnackbar(msgs.success, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      history.push(navigatePathQuery);
    }
  }, [navigatePathQuery, submitting, success]);

  const handleSubmit = useCallback(() => {
    if (!pioIds?.length) {
      enqueueSnackbar('At least one Investment Option must be selected.', {
        variant: 'error',
        autoHideDuration: 5000,
      });
    } else {
      if (token) {
        dispatch(updatePppios(token, planProductId, pioIds));
      }
      setSubmitting(true);
    }
  }, [pioIds, planProductId]);

  const tableFooter = useMemo(
    () => (
      <FormFooterActions
        linkProps={[{ path: navigatePathQuery }]}
        buttonProps={[{ onClick: handleSubmit }]}
      />
    ),
    [handleSubmit, navigatePathQuery],
  );

  return (
    <Container>
      <h3 className="mb-3">Product Investment Options</h3>
      <Spinner requesting={requesting}>
        <Table
          columns={column}
          data={activePlanProductPios}
          hidePagination
          footer={tableFooter}
          errStatus={err}
        />
      </Spinner>
    </Container>
  );
};

export default EditPIOs;
