import moment from 'moment';

const checkValidDate = date => moment(date).isValid();

export const formatDate = (date, format = 'MM/DD/YYYY') => {
  const isValidDate = checkValidDate(date);
  return isValidDate ? moment(date).local().format(format) : '';
};

export const formatDateTime = date => moment(date).local().format('MM/DD/YYYY hh:mm A');
export const esternTimeZone = new Date().toLocaleDateString('en-US', {
  timeZone: 'US/Eastern',
});

export const getRelativeDate = (
  days,
  unit,
  direction,
  format = 'MM/DD/YYYY',
) => {
  const today = moment();
  if (direction === 'previous') {
    return today.subtract(days, unit).format(format);
  }
  if (direction === 'next') {
    return today.add(days, unit).format(format);
  }
  return '';
};

export const calculateAge = (dob) => {
  const isValidDate = checkValidDate(dob);
  const now = moment();
  const birthdate = moment(dob);
  return isValidDate ? now.diff(birthdate, 'years') : '';
};

export const momentDate = (date, format = 'MM/DD/YYYY') => {
  const isValidDate = checkValidDate(date);
  return isValidDate ? moment(date, format) : '';
};

export const currentCalenderYear = moment().year();
