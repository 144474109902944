import React, {
 useCallback, useEffect, useMemo, useState,
} from 'react';

import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { TableDashboard } from '@atoms';
import { conf } from '@config/conf';
import { useAuth } from '@contexts/AuthContext';
import useCsvExportButton from '@hooks/useCsvExportButton';
import { getCarriers } from '@redux/actions/carriersActions';
import { cpStateReset } from '@redux/actions/cpActions';
import { getCustodians } from '@redux/actions/custodiansActions';
import { getProducts } from '@redux/actions/productsActions';
import { getRecordKeepers } from '@redux/actions/rksActions';
import { reset } from '@redux/reducers/formFieldsReducer';

import WorkflowsTable from './components/WorkflowsTable';

const Workflows = () => {
  const { token } = useAuth();
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);

  const rks = useSelector(state => state.rks);
  const custodians = useSelector(state => state.custodians);
  const carriers = useSelector(state => state.carriers);
  const products = useSelector(state => state.products);

  const {
    csvClickRef,
    csvData: { result: exportData = [] } = {},
    getExport,
    loadingResponse,
  } = useCsvExportButton();

  useEffect(() => {
    if (token) {
      dispatch(cpStateReset());
      dispatch(reset());

      dispatch(getRecordKeepers(token));
      dispatch(getCustodians(token));
      dispatch(getCarriers(token));
      dispatch(getProducts(token));

      axios
        .get(conf.serverURL + 'rkchannels/get', {
          headers: {
            'Content-Type': 'application/json',
            accessToken: token,
          },
        })
        .then((resp) => {
          localStorage.setItem('rkChannelID', resp?.data?.data[0]?.userID);
        })
        .catch(err => console.log(err));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (rks?.rks?.data) {
      localStorage.setItem('recordkeeperID', rks?.rks?.data[0]?.recordkeeperID);
    }
    if (custodians?.custodians?.data) {
      localStorage.setItem(
        'custodianID',
        custodians?.custodians?.data[0]?.custodianID,
      );
    }
    if (products?.products?.data) {
      localStorage.setItem('productID', products?.products?.data[0]?.productID);
    }
    if (carriers?.carriers?.data) {
      localStorage.setItem('carrierID', carriers?.carriers?.data[0]?.carrierID);
    }
  }, [
    carriers?.carriers?.data,
    custodians?.custodians?.data,
    products?.products?.data,
    rks?.rks?.data,
  ]);

  const handleExportCsv = () => {
    getExport('workflows/export');
  };

  return (
    <TableDashboard
      pageTitle="Workflows"
      setRefresh={setRefresh}
      exportCsvProps={{
        fileName: 'WorkflowsList',
        csvClickRef,
        csvData: exportData,
        getCsvExport: handleExportCsv,
        loadingResponse,
      }}
    >
      <WorkflowsTable refresh={refresh} setRefresh={setRefresh} />
    </TableDashboard>
  );
};

export default Workflows;
