import React, { useRef } from 'react';

import { useSnackbar } from 'notistack';
import { useIdleTimer } from 'react-idle-timer';
import { useHistory } from 'react-router';

import { msgs, INACTIVE_TIMEOUT } from '@config/conf';
import { useAuth } from '@contexts/AuthContext';

const IdleTimerContainer = () => {
  const idleTimerRef = useRef(null);
  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const onIdleTimer = () => {
    if (user) {
      logout();
      enqueueSnackbar(msgs.authError, {
        variant: 'error',
        autoHideDuration: 5000,
      });
      history.push('/');
    }
  };

  const idleTimer = useIdleTimer({
    crossTab: true,
    ref: idleTimerRef,
    timeout: INACTIVE_TIMEOUT,
    onIdle: onIdleTimer,
  });

  return <div idletimer={idleTimer} />;
};

export default IdleTimerContainer;
