import React, { useCallback, useMemo } from 'react';

import moment from 'moment';
import DatePicker from 'react-datepicker';
import { Form, Input, Button } from 'reactstrap';

import { ExportToCsv } from '@atoms';
import useCsvExportButton from '@hooks/useCsvExportButton';
import useQueryParams from '@hooks/useQueryParams';
import { formatDate } from '@lib/date';

const dateRangeOptions = [
  { label: '', value: '' },
  { label: 'All Entries', value: 'AllEntries' },
  { label: 'Current Month', value: 'CurrentMonth' },
  { label: 'Last Month', value: 'LastMonth' },
  { label: 'Current Quarter', value: 'CurrentQuarter' },
  { label: 'Last Quarter', value: 'LastQuarter' },
  { label: 'Custom Range', value: 'CustomRange' },
  { label: 'Today', value: 'Today' },
];

const CashLedgerTableSearch = ({
  effectiveStartDateInput,
  effectiveEndDateInput,
  setEffectiveStartDateInput,
  setEffectiveEndDateInput,
  setSubmitSearch,
  dateRangeInput,
  setDateRangeInput,
}) => {
  const currentDateAndTime = moment();
  const { buildQueryParams } = useQueryParams();

  const {
    csvClickRef,
    csvData: { response: exportData = [] },
    getExport,
    loadingResponse,
  } = useCsvExportButton();

  const handleCsvExport = useCallback(() => {
    const queryParams = buildQueryParams({
      effectiveStartDate: effectiveStartDateInput,
      effectiveEndDate: effectiveEndDateInput,
    });

    getExport(
      `operations/cashledger/exportCashLedgerTable${
        dateRangeInput ? `?dateRangeRequested=${dateRangeInput}` : ''
      }${queryParams}`,
    );
  }, [getExport]);

  const handleClear = (e) => {
    e.preventDefault();
    setSubmitSearch(true);
    setEffectiveStartDateInput('');
    setEffectiveEndDateInput('');
    setDateRangeInput('Today');
  };

  const csvFileName = useMemo(() => {
    const csvDate = formatDate(currentDateAndTime, 'YYYYMMDD_HHmmss');
    return `CashLedger_${csvDate}.csv`;
  }, [currentDateAndTime]);

  const dateRangeInputData = useMemo(
    () => [
      {
        selectDate: effectiveStartDateInput,
        setSelectDate: setEffectiveStartDateInput,
        placeholder: 'Effective Start Date (MM/DD/YYYY)',
      },
      {
        selectDate: effectiveEndDateInput,
        setSelectDate: setEffectiveEndDateInput,
        placeholder: 'Effective End Date (MM/DD/YYYY)',
      },
    ],
    [
      effectiveEndDateInput,
      effectiveStartDateInput,
      setEffectiveEndDateInput,
      setEffectiveStartDateInput,
    ],
  );

  const renderDateInputs = useMemo(() => {
    if (dateRangeInput === 'CustomRange') {
      return (
        <>
          {dateRangeInputData.map((data) => {
            const { placeholder, selectDate, setSelectDate } = data || {};
            return (
              <DatePicker
                key={placeholder}
                className="form-control table__search-input"
                selected={selectDate ? new Date(selectDate) : null}
                onChange={date => setSelectDate(formatDate(date))}
                placeholderText={placeholder}
              />
            );
          })}
        </>
      );
    }
    return null;
  }, [dateRangeInput, dateRangeInputData]);

  return (
    <div className="table__search mb-0">
      <Form
        inline
        className="w-100"
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          setSubmitSearch(true);
        }}
      >
        <Input
          type="select"
          placeholder="Date Range"
          className="table__search-input"
          value={dateRangeInput}
          onChange={(e) => {
            setDateRangeInput(e.target.value);
            if (!dateRangeInput === 'CustomRange') {
              setEffectiveStartDateInput('');
              setEffectiveEndDateInput('');
            }
          }}
        >
          {dateRangeOptions.map((range, index) => (
            <option key={`${range.value}_${index}`} value={range.value}>
              {range.label}
            </option>
          ))}
        </Input>
        {renderDateInputs}
        <Button
          color="blue"
          className="text-light table__search-input mb-0"
          // disabled={!enableButtons}
        >
          Filter
        </Button>
        <ExportToCsv
          csvData={exportData}
          csvRef={csvClickRef}
          fileName={csvFileName}
          getCsvExport={handleCsvExport}
          loadingResponse={loadingResponse}
          colorVariant="blue"
          className="m-0 text-light table__search-input"
        />
        {dateRangeInput || effectiveStartDateInput || effectiveEndDateInput ? (
          <Button
            type="reset"
            color="blue"
            className="text-light table__search-input mb-0"
            onClick={handleClear}
          >
            Clear
          </Button>
        ) : null}
      </Form>
    </div>
  );
};

export default CashLedgerTableSearch;
