import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';

import { msgs } from '@config/conf';
import {
  sanitizeValue,
  unauthorizedError,
} from '@containers/components/helpers';
import { useAuth } from '@contexts/AuthContext';
import useToast from '@hooks/useToast';
import { sanitizeNumber } from '@lib/number';
import { createPlan } from '@redux/actions/plansActions';

import PlanForm from '../PlanForm/PlanForm';

const recordkeeperID = localStorage.getItem('recordkeeperID');
const custodianId = localStorage.getItem('custodianID');

const defaultData = {
  recordkeeperID,
  planName: '',
  federalTaxID: '',
  carrierPlanIdentifier: '',
  address1: '',
  address2: '',
  city: '',
  stateCode: 'AK',
  zip: '',
  countryCode: 'USA',
  contactName: '',
  contactPhone: '',
  contactEmail: '',
  custodianID: custodianId,
  custodialAccount: '',
  notes: '',
  status: 'Active',
  incomeActivationStatus: '',
  custodialTpaCode: null,
  custodialTrustAccount: null,
  rkAccount: null,
};

const AddPlan = () => {
  const dispatch = useDispatch();
  const { token, logout } = useAuth();
  const plans = useSelector(state => state.plans);
  const { showErrorToast, showSuccessToast, validateRequiredField } = useToast();
  const history = useHistory();
  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (valid) {
      if (plans?.err) {
        if (unauthorizedError(plans)) {
          logout();
          showErrorToast(msgs.authError);
          history.push('/');
        } else {
          showErrorToast(plans.err || msgs.error);
        }
      } else if (plans?.success) {
        showSuccessToast();
        history.push('/plans');
      }
    }
  }, [plans, history, valid]);

  const handleForm = (values) => {
    Object.keys(values).forEach((key) => {
      if (typeof values[key] === 'string') {
        values[key] = sanitizeValue(values[key]);
      }
    });

    const {
      planName,
      carrierPlanIdentifier,
      address1,
      city,
      zip,
      custodialTpaCode,
      custodialTrustAccount,
      rkAccount,
      federalTaxID,
    } = values;

    const isValid = validateRequiredField({
      'plan Name': planName,
      'plan Identifier': carrierPlanIdentifier,
      'Address 1': address1,
      City: city,
      Zip: zip,
    });

    if (!isValid) {
      return;
    }

    const payloadValues = {
      ...values,
      federalTaxID: federalTaxID ? sanitizeNumber(federalTaxID) : '',
      custodialTpaCode: !custodialTpaCode?.length ? null : custodialTpaCode,
      custodialTrustAccount: !custodialTrustAccount?.length
        ? null
        : custodialTrustAccount,
      rkAccount: !rkAccount?.length ? null : rkAccount,
    };

    dispatch(createPlan(token, payloadValues));
    setValid(true);
  };

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Plan Detail</h3>

      <PlanForm data={defaultData} redirectUrl="/plans" onSubmit={handleForm} />
    </Container>
  );
};

export default AddPlan;
