import {
 useCallback, useEffect, useMemo, useState,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Badge, Card, CardBody } from 'reactstrap';

import { TableDashboard, Spinner, Table } from '@atoms';
import { pagination } from '@config/conf';
import { formatCurrency, getPagination } from '@containers/components/helpers';
import { useAuth } from '@contexts/AuthContext';
import useQueryParams from '@hooks/useQueryParams';
import { cpStateReset } from '@redux/actions/cpActions';
import { getCustodians } from '@redux/actions/custodiansActions';
import { getPlans } from '@redux/actions/plansActions';
import { getProductType } from '@redux/actions/productTypeActions';
import { getRecordKeepers } from '@redux/actions/rksActions';
import { reset } from '@redux/reducers/formFieldsReducer';

import PlansTableSearch from './components/PlansTableSearch';

const IMMEDIATE_ANNUITY = 'Immediate Annuity';

const Plans = () => {
  const { token } = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();
  const { getQueryParam, buildQueryParams } = useQueryParams();

  const plansData = useSelector(state => state.plans);
  const recordkeepers = useSelector(state => state.rks?.rks?.data);
  const custodians = useSelector(state => state.custodians?.custodians?.data);
  const productTypeData = useSelector(
    state => state.productType?.productType,
  );

  const page = getQueryParam('page');
  const sortPlanName = getQueryParam('sortPlanName') || '';
  const sortStatus = getQueryParam('sortStatus');
  const [filter, setFilter] = useState(getQueryParam('search') || '');

  const [refresh, setRefresh] = useState(false);
  const [submitSearch, setSubmitSearch] = useState(false);
  const [pageSize, setPageSize] = useState(
    getQueryParam('pageSize') || pagination.length,
  );

  const { offset, pageNumber } = getPagination(pageSize, page);
  const { err, requesting, plans } = plansData;
  const { data = [], recordCount = 0 } = plans || {};

  useEffect(() => {
    dispatch(reset());
    dispatch(cpStateReset());
  }, []);

  const refreshString = () => {
    if (sortPlanName) {
      return `Plans.PlanName ${sortPlanName.toUpperCase()}, Products.ProductName ASC`;
    }
    if (sortStatus) {
      return `Plans.Status ${sortStatus.toUpperCase()}, Plans.PlanName ASC, Products.ProductName ASC`;
    }
    return '';
  };

  const refStr = refreshString();

  const immediateAnnuity = useMemo(
    () => productTypeData
        ?.filter(typeName => typeName?.productTypeName === IMMEDIATE_ANNUITY)
        ?.find(typeId => typeId?.productTypeID)?.productTypeID,
    [productTypeData],
  );

  const navigatePath = useCallback(
    (id = '', childParams = '', path = 'plans') => {
      const query = buildQueryParams({
        search: filter.trimStart(),
        sortPlanName,
        sortStatus,
        pageSize,
      });
      const params = id
        ? `/${path}/${id}/${childParams}from=${pageNumber}`
        : '?page=1';
      return `${params}${query}`;
    },
    [pageNumber, pageSize, sortPlanName, sortStatus, filter],
  );

  useEffect(() => {
    if (submitSearch) {
      const pageReload = navigatePath();
      history.push(pageReload);
    } else if (token) {
      !productTypeData?.length && dispatch(getProductType(token));
      dispatch(getRecordKeepers(token));
      dispatch(getCustodians(token));
      dispatch(getPlans(token, pageSize, offset, refStr, filter));
    }
    setRefresh(false);
    setSubmitSearch(false);
  }, [
    pageSize,
    offset,
    refresh,
    submitSearch,
    refStr,
    productTypeData?.length,
  ]);

  const columns = useMemo(
    () => [
      {
        id: 'expanded-chevron',
        header: '',
        size: 0,
        cell: (tableRow) => {
          const { expandedRowIds, row } = tableRow || {};
          const className = `lnr ${
            expandedRowIds?.includes(row.id)
              ? 'lnr-chevron-down'
              : 'lnr-chevron-right'
          }`;

          return (
            <div className="expanded_chevron">
              <i className={className} />
            </div>
          );
        },
      },
      {
        id: 'planName',
        header: 'Plan Name',
        accessorKey: 'planName',
        enableSorting: true,
      },
      {
        id: 'status',
        header: 'Status',
        accessorKey: 'planStatus',
        enableSorting: true,
      },
      {
        id: 'recordkeeper',
        header: 'Recordkeeper',
        cell: (tableRow) => {
          const { recordkeeperID } = tableRow.row.original || {};
          const recordkeeperName = recordkeepers
            ?.filter(val => val?.recordkeeperID === recordkeeperID)
            ?.find(val => val?.recordkeeperName)?.recordkeeperName;
          return recordkeeperName || '-';
        },
      },
      {
        id: 'custodian',
        header: 'Custodian',
        cell: (tableRow) => {
          const { custodianID } = tableRow.row.original || {};
          const custodianName = custodians
            ?.filter(val => val?.custodianID === custodianID)
            ?.find(val => val?.custodianName)?.custodianName;
          return custodianName || '-';
        },
      },
      {
        id: 'actions',
        header: '',
        cell: (tableRow) => {
          const { planID } = tableRow?.row?.original || {};
          const planDetailsPage = navigatePath(planID, 'edit?');
          const addProductPage = navigatePath(planID, 'products/add?');
          return (
            <div className="flex justify-content-end pr-2 my-1">
              <Link className="mr-2" to={planDetailsPage}>
                <Badge className="btn-blue btn-badge align-middle">
                  View / Edit
                </Badge>
              </Link>
              <Link to={addProductPage}>
                <Badge color="success">Add Product</Badge>
              </Link>
            </div>
          );
        },
      },
    ],
    [custodians, navigatePath, recordkeepers],
  );

  const productTableColumns = useCallback(
    planId => [
      {
        id: 'productName',
        header: 'Product Name',
        accessorKey: 'productName',
      },
      {
        id: 'carrierProductCode',
        header: 'Product Code',
        accessorKey: 'carrierProductCode',
      },
      {
        id: 'productIdentifier',
        header: 'Product Identifier',
        accessorKey: 'productIdentifier',
      },
      {
        id: 'minInitialPurchase',
        header: 'Initial Purchase',
        accessorFn: row => formatCurrency(row?.minInitialPurchase),
      },
      {
        id: 'planProductStatus',
        header: 'Status',
        accessorKey: 'planProductStatus',
      },
      {
        id: 'view-edit-product',
        header: '',
        cell: (tableRow) => {
          const { cell } = tableRow || {};
          const { original } = cell?.row || {};
          const { planProductID, productID, productTypeID } = original || {};
          const isImmediateAnnuity = immediateAnnuity === productTypeID;
          const gotoEditPlanProduct = navigatePath(
            planId,
            `product/${planProductID}?`,
          );
          const gotoPioDetails = navigatePath(
            planProductID,
            `product/${productID}/pios?planId=${planId}&`,
            'plan',
          );
          const gotoFactorsDetails = navigatePath(
            planProductID,
            `product/${productID}/factors?planId=${planId}&`,
            'plan',
          );
          return (
            <div className="flex justify-content-end pr-2">
              <Link className="mr-2" to={gotoEditPlanProduct}>
                <Badge className="btn-blue btn-badge pointer">
                  View / Edit
                </Badge>
              </Link>
              <Link to={gotoPioDetails}>
                <Badge color="success" className="pointer">
                  PIOs
                </Badge>
              </Link>
              {isImmediateAnnuity ? (
                <Link className="ml-1" to={gotoFactorsDetails}>
                  <Badge color="success" className="pointer">
                    Factors
                  </Badge>
                </Link>
              ) : null}
            </div>
          );
        },
      },
    ],
    [immediateAnnuity, navigatePath],
  );

  const PlanSubTable = useCallback(
    (subTableprops) => {
      const { row } = subTableprops || {};
      const { product, planID } = row?.original || {};
      const subColumns = productTableColumns(planID);
      return <Table columns={subColumns} data={product} hasNestedTable />;
    },
    [productTableColumns],
  );

  const pageProps = {
    total: recordCount,
    pageSize,
    setPageSize,
    pageNumber,
  };

  return (
    <TableDashboard
      pageTitle="Plans"
      navigatePath="/plans/add"
      setRefresh={setRefresh}
    >
      <Spinner requesting={requesting}>
        <Card className="pb-2">
          <CardBody className="py-2 bg-green">
            <PlansTableSearch
              filter={filter.trimStart()}
              setFilter={setFilter}
              setSubmitSearch={setSubmitSearch}
            />
          </CardBody>
        </Card>
        <Table
          columns={columns}
          data={data}
          renderNestedTable={PlanSubTable}
          errStatus={err}
          pageProps={pageProps}
        />
      </Spinner>
    </TableDashboard>
  );
};

export default Plans;
